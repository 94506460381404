<template>
  <div>
      <user-list />
  </div>
</template>

<script>
import UserList from './components/UserList.vue'
export default {
  components: { UserList },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
