<template>
  <div>
    <user-pop
        :userPopshow="userPop_show"
        @Popshow="popshow"
        :title="title"
        :data="data"
        :rolelist="rolelist"
        :UserInfo="getUserInfo"
        @newUser="newUser"
        @update="userList"
    />
    <!-- 头部 -->
    <div class="user-top">
      <el-button class="new_btn" size="mini" @click="newAccount">新增子账号</el-button>
    </div>
    <div class="list">
      <el-row class="list_top">
        <el-col :span="8" style="padding-left: 20px">
          电话号码
        </el-col>
        <el-col :span="8">更新时间</el-col>
        <el-col :span="4">
          角色
        </el-col>
        <el-col :span="4">
          操作
        </el-col>
      </el-row>
      <div class="list_body">
        <el-row class="list_item" v-for="(item, index) in UserList" :key="index">
          <el-col :span="8" style="padding-left: 20px">
            {{ item.phone }}
          </el-col>
          <el-col :span="8">
            {{setTime(item.created_at)}}
          </el-col>
          <el-col :span="4">
            <span v-for="(val,i) in item.roles" :key="i"  style="margin-right: 10px">{{val.title}}</span>
          </el-col>
          <el-col :span="4" style="cursor: pointer">
            <span @click="modifyUser(item)" :class="{'grayDisabled':item.phone ===userInfo.phone }"> 修改</span>
            <div :class="{'color':Number(item.is_enable) === 0,'grayDisabled':item.phone ===userInfo.phone }" @click="disabledAccount(item)">{{Number(item.is_enable) === 1 ? '禁用' : '启用'}}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import UserPop from './UserPop.vue'
import {
  addSubAccount,
  DeletsubAccount,
  editSubAccount,
  getRoleList,
  subAccount,
} from '@/api/rbac'
import {mapMutations, mapState} from "vuex";
import {ableMerchantManager, departmentList} from "@/api/rbac";
import {dateFormat} from "@/utils/timejs";

export default {
  components: {UserPop},
  data() {
    return {
      userPop_show: false,
      title: '新增子账号',
      UserList: [],
      rolelist: [],
      data:[],
      // 用户详细
      getUserInfo: {
        department: '',  //部门
        name: '',   //用户名
        password: '',  //密码
        email: '',    //邮箱
        phone: '',    //电话
        type: [],    //角色
        remark: '',  //备注
        id:'',
      },
    }
  },
  created() {
    this.userList()
    // this.getDepartmentList()
    this.roleList()
  },
  mounted() {

  },
  computed:{
    ...mapState(['userInfo']),
    setTime(){
      return (item) => {
       return  dateFormat(item)
      }
    }
  },

  methods: {
    ...mapMutations(['subAccountInformation']),


    //修改用户
    async modifyUser(item){
      const { phone, roles, id} = item
      if(this.userInfo.phone !== phone) {
        //获取详情
        this.title = '编辑子账号'
        this.getUserInfo.phone = phone
        this.getUserInfo.type = roles
        this.getUserInfo.id = id
        this.userPop_show = true
      }
    },

    //禁用 / 启用
     disabledAccount(item){
      if(this.userInfo.phone !== item.phone) {
        this.$confirm('此操作将禁用该账户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { message } = await ableMerchantManager({
            merchantManagerId:item.id
          })
          this.$message.success(message)
          await this.userList()
        }).catch((e) => {
          if(e !== 'cancel') {
            this.$message.error(e)
          }
        });
      }
    },
    //新增
    newAccount() {
      this.title = '新增子账号'
      this.userPop_show = true
    },
    //   修改
    async amend(i, id, val) {

      this.variable = i
      this.UserID = id

      if (!!val) {
        this.userPop_show = true
        try {
          const {data, msg} = await subAccount({subAccountId: this.UserID})

          let type_id = []
          for (let i = 0; i < data?.info?.roles.length; i++) {
            type_id.push(data?.info?.roles[i].id)
          }
          this.UserInfo.department = data?.info?.departments[0]?.id
          this.UserInfo.name = data?.info?.alias
          this.UserInfo.phone = data?.info?.phone
          this.UserInfo.email = data?.info?.email
          this.UserInfo.type = type_id

          this.subAccountInformation(this.UserInfo)
        } catch (e) {
          this.$message.error(e)
        }

      }


    },
    //   获取修改值
    popshow(val) {
      this.userPop_show = val
      this.getUserInfo.phone = null
      this.getUserInfo.type = []
      this.getUserInfo.id = null
    },
    // 获取列表
    async userList() {
      try {
        const { data } = await departmentList()
        this.UserList = data
      } catch (e) {
        this.$message.error(e)
      }
    },
    //   获取角色列表
    async roleList() {
      try {
        const {data} = await getRoleList()
        console.log(data)
        this.rolelist = data

      } catch (e) {
        console.log(e);
        this.$message.error(e)
      }
    },
    //   编辑和新增用户
    async newUser(val) {

      let str = val.type.join(",")

      if (this.variable === 0) {
        try {
          const {data, message} = await addSubAccount(
              {
                departmentId: val.department,
                alias: val.name,
                password: val.password,
                email: val.email,
                phone: this.$encryptionByPhone(val.phone),
                verifyCode: val.code,
                roleId: str,
                remark: val.remark
              }
          )
          await this.userList()
          this.$message({
            message,
            type: 'success'
          });
        } catch (e) {
          this.$message.error(e);
        }
      } else {
        try {
          const {data, message} = await editSubAccount(
              {
                subAccountId: this.UserID,
                departmentId: val.department,
                alias: val.name,
                password: val.password,
                email: val.email,
                phone: val.phone,
                roleId: str,
                remark: val.remark
              }
          )
          await this.userList()
          this.$message({
            message,
            type: 'success'
          });
        } catch (e) {
          this.$message.error(e);
        }
      }
    },
    // 全选框
    handleCheckAllChange(val) {

      let arr = []
      this.UserList.forEach(e => {
        arr.push(e.id)
      });
      this.checkedCities = val ? arr : []
      this.isIndeterminate = false
    },
    // 单选
    handleCheckedCitiesChange(val) {
      let arr = []

      this.UserList.forEach(e => {
        arr.push(e.id)
      });
      this.singleButton = val
      this.checkAll = val.length === this.UserList.length
      this.isIndeterminate = val.length > 0 && val.length < this.UserList.length


    },

    // 确认删除
    async Sure() {

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          let str = this.checkedCities.join(',')
          await DeletsubAccount(
              {
                subAccountId: str
              }
          )
          this.value = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.isIndeterminate = false
          await this.userList()
        } catch (e) {
          this.$message.error(e)
          this.isIndeterminate = false
          this.checkAll = false
          this.checkedCities = []
          this.value = ''
        }

      })

    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-col {
  border: 1px solid transparent;
}
.user-top {
  .new_btn {
    width: 130px;
    height: 40px;
    background: #FA4A14;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
  }

  padding: 0 0 20px;
  border-bottom: 1px solid #d8d8d8;
}

.operation {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  .operation_left {
    .batch {
      margin-left: 10px;
    }

    .s_text {
      margin-left: 20px;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }

    .num {
      width: 60px;
    }
  }

  .operation_rigth {
    width: 412px;
    height: 26px;
    border-radius: 2px;
    border: 1px solid #999999;

    .inp_search {
      border: 1px solid #fff;
    }

    .search_btn {
      background: #fff;
      height: 22px;
      padding: 0;
      width: 40px;
    }

    /deep/ .el-input-group__append {
      background: #fff;
      border: 0;
    }

    /deep/ .el-input-group--append {
      .el-input__inner {
        border: 0;
        height: 22px;
      }
    }
  }
}

.list {
  font-size: 14px;

  .check {
    text-align: center;
  }

  .color {
    color: #FA4A14;
    cursor: pointer;
  }

  .list_top {
    padding: 15px 0;
    background: #F5F5F5;
  }

  .list_body {
    .list_item {
      padding: 15px 0;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  /deep/ .el-checkbox__label {
    display: none;
  }
}

.bgCOlor {
  background: #FA4A14;
  color: #fff;
}

.grayDisabled {
  color: #cccccc;
  cursor: not-allowed;
}
</style>
