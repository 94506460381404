<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="userPopshow"
        width="520px"
        :before-close="handleClose">
      <el-form :model="InfoForm" :rules="rules" ref="ruleForm">
        <!--    <el-form-item label="用户部门"  prop="department">-->
        <!--      <el-select v-model="InfoForm.department" placeholder="请选择活动区域">-->
        <!--        <el-option-->
        <!--            v-for="(item, index) in data"-->
        <!--            :key="index"-->
        <!--            :label="item.name" :value=item.id></el-option>-->
        <!--      </el-select>-->
        <!--    </el-form-item>-->
        <!--    <el-form-item label="用户名" prop="name">-->
        <!--      <el-input v-model="InfoForm.name" class="inp" placeholder="至少输入一个字符" ></el-input>-->
        <!--    </el-form-item>-->
        <el-form-item label="电话" prop="phone">
          <el-input v-model.trim="InfoForm.phone" class="inp" placeholder="请输入手机号"
                    :disabled="getUserInfoState"></el-input>
        </el-form-item>
        <el-form-item label="密码" :prop="getUserInfoState ? '' : 'password'" :error="setError">
          <el-input v-model.trim="InfoForm.password" class="inp" type="password" placeholder="请输入6位及以上数字加字母"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :prop="getUserInfoState ? '' : 'confirmPassword'" :error="setConfirmPassword">
          <el-input v-model.trim="InfoForm.confirmPassword" class="inp" type="password"
                    placeholder="请输入6位及以上数字加字母"></el-input>
        </el-form-item>
        <!--    <el-form-item label="邮箱" prop="email">-->
        <!--      <el-input v-model="InfoForm.email" class="inp"></el-input>-->
        <!--    </el-form-item>-->

        <!--    <el-form-item label="验证码" prop="code" >-->
        <!--      <div class="code">-->
        <!--      <el-input v-model="InfoForm.code" class="inp_code"></el-input>-->
        <!--      <span class="span_code" v-if="codeShow" @click="getCode">获取验证码</span>-->
        <!--      <span class="span_code" v-else >{{codeTime}}s</span>-->
        <!--      </div>-->
        <!--    </el-form-item>-->
        <el-form-item label="角色" prop="type">
          <el-checkbox-group v-model="InfoForm.type">
            <el-checkbox v-for="(item,index) in rolelist" :key="index" :label="item.id" :value="item.id">
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!--  <el-form-item label="备注" prop="remark">-->
        <!--      <el-input v-model="InfoForm.remark" class="inp" type="textarea"></el-input>-->
        <!--    </el-form-item>-->
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {subAccount} from '@/api/rbac'
import {sendVerifyCode} from '@/api/login'
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64'
import CryptoJS from 'crypto-js'
import {mapState} from 'vuex'
import {createManageAccount, editInfo} from "../../../../../api/rbac";

export default {
  props: {
    userPopshow: {
      type: Boolean
    },
    title: {
      type: String
    },
    data: {
      type: Array
    },
    rolelist: {
      type: Array
    },
    UserInfo: {
      type: Object
    }
  },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.InfoForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // 表单数据
      InfoForm: {
        department: '',  //部门
        name: '',   //用户名
        password: undefined,  //密码
        email: '',    //邮箱
        phone: '',    //电话
        type: [],    //角色
        remark: '',  //备注
        code: '',  //验证码
        confirmPassword: undefined, //确认密码
      },
      setError:'',
      codeShow: true,  //显示验证码
      codeTime: 60,//验证证码倒计时
      setConfirmPassword:'',
      // 表单验证
      rules: {
        department: [
          {required: true, message: '请选择部门', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            message: '请输入正确的手机号',
            trigger: 'blur',
            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
          },
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {message: '请输入正确的验证码', trigger: 'blur', pattern: /^\d{4}(,\d{4})*$/,},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {message: '请输入由字母加数字的组合', trigger: 'blur', pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,},
          {max: 20, min: 6, message: '密码长度为6-20', trigger: 'blur'}
        ],
        confirmPassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择角色', trigger: 'change'},
        ],
        email: [{message: '请输入正确的邮箱号', trigger: 'blur', pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,},]
      }
    }
  },
  created() {

  },
  mounted() {

  },

  computed: {
    // ...mapState({
    //   subAccountInfo:state => state.subAccountInfo
    // }),
    getUserInfoState() {
      return !!this.UserInfo.phone
    }
  },
  methods: {
    handleClose() {
      this.$emit('Popshow', false)
      this.InfoForm = {
        department: '',  //部门
        name: '',   //用户名
        password: undefined,  //密码
        email: '',    //邮箱
        phone: '',    //电话
        code: '',    //验证码
        type: [],    //角色
        remark: '',  //备注
        confirmPassword: undefined
      }
      this.$refs.ruleForm.resetFields()
    },
    //倒计时
    countdown() {
      if (!this.codeShow) {
        setTimeout(() => {
          this.codeTime--
          this.countdown()
          if (this.codeTime === 0) {
            this.codeTime = 60
            this.codeShow = true
          }
        }, 1000)
      }
    },
    //获取验证码
    async getCode() {
      this.codeShow = false
      const obj = {
        'abf9cef7-731d-4fc6-b0a9-8f0b26a8f93b': '06c56a89949d617def52f371c357b6db',
        '94999426-74d0-453f-94f9-86cff8aa5e0d': '949d617def52f371'
      }
      const fkey = CryptoJS.enc.Utf8.parse(obj['abf9cef7-731d-4fc6-b0a9-8f0b26a8f93b']);
      const fiv = CryptoJS.enc.Utf8.parse(obj['94999426-74d0-453f-94f9-86cff8aa5e0d']);
      const enc = CryptoJS.AES.encrypt(this.InfoForm.phone, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      let phone = enc.ciphertext.toString(CryptoJS.enc.Base64);
      // return enc.ciphertext.toString(CryptoJS.enc.Base64);
      try {
         await sendVerifyCode({phone: phone})

      } catch (e) {
        console.log(e)
      }
      this.countdown()
    },
    sure() {
      this.$refs.ruleForm.validate(async (valid) => {
        //#region
        // this.codeTime = 60
        // this.codeShow = true
        // if(valid) {
        //
        //   if(this.variable === 0){
        //     this.InfoForm.password = Base64.stringify(sha256(this.InfoForm.password))
        //     this.$emit('newUser',this.InfoForm)
        //     this.InfoForm = {}
        //   } else {
        //     this.clas.password = Base64.stringify(sha256(this.clas.password))
        //     this.$emit('newUser',this.clas)
        //     this.clas = {}
        //   }
        //   this.handleClose()
        //   this.InfoForm = {
        //     department:'',  //部门
        //     name: '',   //用户名
        //     password:'',  //密码
        //     email:'',    //邮箱
        //     phone:'',    //电话
        //     code:'',    //验证码
        //     type:[],    //角色
        //     remark:'',  //备注
        //   }
        // } else {
        //   this.$message.error('请填写完毕再保存')
        // }
        //#endregion
        if (valid) {
          let password =this.InfoForm.password ? Base64.stringify(sha256(this.InfoForm.password)) :undefined
          let confirmPassword = this.InfoForm.password ? Base64.stringify(sha256(this.InfoForm.confirmPassword)): undefined
          console.log(password)
          console.log(confirmPassword)
          if (this.getUserInfoState) {
            //编辑
            try {
              const {message} = await editInfo({
                password,
                passwordRepeat:confirmPassword,
                roleId: this.InfoForm.type,
                merchantManagerId: this.UserInfo.id
              })
              this.$message.success(message)
              this.handleClose()
              this.$emit('update')
            } catch (e) {
              this.$message.error(e)
            }
          } else {
            //新增
            if(!this.InfoForm.password) {
              this.setError='请输入密码'
              return false
            } else if(!this.InfoForm.confirmPassword) {
              this.setConfirmPassword='请输入密码'
              return false
            }
            try {
              const {message} = await createManageAccount({
                phone: this.InfoForm.phone,
                password: password,
                passwordRepeat: confirmPassword,
                roleId: this.InfoForm.type
              })
              this.$message.success(message)
              this.handleClose()
              console.log(this.InfoForm);
              this.$emit('update')
            } catch (e) {
              this.$message.error(e)
            }
          }

        }
      })

    }
  },
  watch: {
    UserInfo: {
      handler() {
        this.InfoForm.phone = this.UserInfo.phone
        this.UserInfo.type.forEach(item => {
          if (!!item.id) {
            this.InfoForm.type.push(item.id)
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  // height: 571px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px 4px rgba(51, 51, 51, 0.3);
  border-radius: 10px;
  padding: 0 20px;

  .el-dialog__header {
    // margin: 0 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #d8d8d8;
  }

  .el-dialog__body {
    padding: 20px 10px;
    border-bottom: 1px solid #d8d8d8;
  }
}

.cascader {
  width: 340px;
}

.inp {
  width: 345px;
  height: 40px;
}

.code {
  display: flex;
  padding-right: 40px;
  //justify-content: space-between;
  .inp_code {
    width: 200px;
    padding-right: 20px;
  }

  .span_code {
    line-height: 60px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    color: #FA4A14
  }
}

.el-select {
  width: 345px;
  height: 40px;
}

.text_r {
  text-align: right;
}

/deep/ .el-form-item__label {
  width: 80px;
}

/deep/ .el-tree-node__content {
  height: 40px;

}

.el-checkbox-group {
  padding-left: 100px;
}

/deep/ .el-form-item__error {
  left: 77px;
}
</style>
